import { render, staticRenderFns } from "./BulkCrmsShipInfoDetailList.vue?vue&type=template&id=42cfdb7a&scoped=true"
import script from "./BulkCrmsShipInfoDetailList.vue?vue&type=script&lang=js"
export * from "./BulkCrmsShipInfoDetailList.vue?vue&type=script&lang=js"
import style0 from "./BulkCrmsShipInfoDetailList.vue?vue&type=style&index=0&id=42cfdb7a&prod&scoped=true&lang=css"
import style1 from "./BulkCrmsShipInfoDetailList.vue?vue&type=style&index=1&id=42cfdb7a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42cfdb7a",
  null
  
)

export default component.exports